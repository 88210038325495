import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import Loader from '../../components/Loader';
import WistiaPopoverVideo from '../../components/WistiaPopoverVideo';
import QuoteCard from '../../components/QuoteCard';

import './style.scss';
import ConsentCheckbox from '../../components/ConsentCheckbox';

const VisionPage = () => {
    return (
        <Layout navTheme="light">
            <Seo title="Vision" />
            <div className="vision-page">
                <Container className="container-1">
                    <Row className="hero-row">
                        <Col className="one-half">
                            <p className="hero-subtext white-text">
                                GlassHive Background
                            </p>
                            <h1 className="white-text">
                                The Vision Behind GlassHive
                            </h1>
                        </Col>
                        <Col className="centered-text video-col one-half">
                            <WistiaPopoverVideo id={'88uwcdehj1'}>
                                <img
                                    className="thumbnail"
                                    src={require('../../images/vision/honey-vision-preview.png')}
                                    alt="GlassHive video preview"
                                />
                            </WistiaPopoverVideo>
                        </Col>
                    </Row>
                    <img
                        className="white-divider"
                        src={require('../../svgs/common/white-divider.svg')}
                        alt="white divider"
                    />
                </Container>
                <Container className="container-2">
                    <Row>
                        <Col className="one-half">
                            <QuoteCard
                                quote='"I just want salespeople to do a normal, good sales day work and have all that information be automatically recorded."'
                                cite="-Giovanni Sanguily"
                            />
                        </Col>
                        <Col className="one-half">
                            <p>
                                Sales and marketing are the IT channel’s
                                greatest weaknesses.
                            </p>
                            <p>
                                GlassHive lets you defeat that beast once and
                                for all.
                            </p>
                            <p>
                                In this video, Giovanni Sanguily, founder and
                                CEO of GlassHive and TRIdigital Marketing, talks
                                about the vision of GlassHive and how his humble
                                beginning as a channel salesperson led him to
                                create a tool to help channel partners.
                            </p>
                            <p>
                                The vision of GlassHive was to harness raw sales
                                and marketing data and deliver it back to
                                companies in informative and influential ways.
                                The platform as a whole gives you the data and
                                tools you need to understand and consistently
                                improve your sales and marketing efforts.
                            </p>
                            <p>
                                Watch the full video above to get the whole
                                rundown straight from the man himself.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-3">
                    <Row className="flexed-mobile stretch">
                        <Col className="one-half centered-text rel z-index-9 small-width min-height-100">
                            <form
                                id="vision-form"
                                className="pink-form"
                                method="POST"
                                name="Vision Form"
                                action="/vision/#thanks"
                                encType="multipart/form-data"
                                netlify="true"
                                netlify-honeypot="bot-field"
                                data-category="Consultation"
                                onSubmit={e => {
                                    e.preventDefault();
                                    const submitButton = document.getElementById(
                                        'sbmt-form-btn'
                                    );
                                    const loader = document.querySelector(
                                        '.loader'
                                    );
                                    const formName = document.getElementById(
                                        'vision-form'
                                    );

                                    loader.style.display = 'block';
                                    submitButton.style.display = 'none';

                                    fetch(formName.getAttribute('action'), {
                                        method: 'POST',
                                        body: new FormData(
                                            document.getElementById(
                                                'vision-form'
                                            )
                                        ),
                                    }).then(res => {
                                        if (res.status === 200) {
                                            document.querySelector(
                                                '#vision-form'
                                            ).style.display = 'none';
                                            document.querySelector(
                                                '.contact-thank-you'
                                            ).style.display = 'block';
                                        } else {
                                            loader.style.display = 'none';
                                            document.getElementById(
                                                'error-msg'
                                            ).style.display = 'block';
                                            submitButton.style.display =
                                                'block';
                                        }
                                    });
                                }}
                            >
                                <input
                                    type="hidden"
                                    name="form-name"
                                    value="vision-form"
                                />
                                <input
                                    type="hidden"
                                    name="bot-field"
                                    id="bot"
                                />
                                <h3 className="white-text form-title">
                                    Does GlassHive sound like it can help with
                                    your sales and marketing efforts? Fill out
                                    the form below to talk to a GlassHive
                                    channel expert today!
                                </h3>
                                <div className="form-fields-container">
                                    <div className="field name-field">
                                        <label htmlFor="firstName" hidden>
                                            What’s your name?
                                        </label>
                                        <input
                                            type="text"
                                            name="firstName"
                                            id="firstName"
                                            placeholder="Name"
                                            required
                                        ></input>
                                    </div>
                                    <div className="field email-field">
                                        <label hidden htmlFor="email">
                                            What’s your email address?
                                        </label>
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            placeholder="Email"
                                            required
                                        ></input>
                                    </div>
                                    <div className="field company-field">
                                        <label
                                            className="second-label"
                                            hidden
                                            htmlFor="company"
                                        >
                                            What company are you with?
                                        </label>
                                        <input
                                            type="text"
                                            name="company"
                                            placeholder="Company"
                                            id="company"
                                        ></input>
                                    </div>
                                    <div className="field comment-field">
                                        <label
                                            hidden
                                            className="textarea-label"
                                            htmlFor="message"
                                        >
                                            What question can we answer for you?
                                        </label>
                                        <textarea
                                            type="text"
                                            name="message"
                                            id="message"
                                            placeholder="Comments"
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="hide-me field">
                                    <input
                                        data-form-type="Consultation"
                                        className="formcat"
                                    ></input>
                                </div>
                                <div style={{ marginTop: 20 }}>
                                    <ConsentCheckbox />
                                </div>
                                <div className="submit-btn">
                                    <Loader />
                                    <p id="error-msg">
                                        Looks like there was a problem
                                        submitting your form. Please ensure ALL
                                        form fields are filled out and try
                                        again.
                                    </p>
                                    <button
                                        id="sbmt-form-btn"
                                        type="submit"
                                        className="pink-button"
                                    >
                                        Get in contact
                                    </button>
                                </div>
                            </form>
                            <div
                                className="contact-thank-you reach"
                                id="thanks"
                            >
                                <h5>
                                    Thank you for contacting us. We'll be in
                                    touch shortly!
                                </h5>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default VisionPage;
